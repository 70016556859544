import axios from "axios";
import Config from "../config";

const getRoomByUserName = username => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/room/join-by-username",
        data: { username }
    });
};

export default getRoomByUserName;
