import axios from 'axios';
import { Cookies } from "react-cookie";

const setAuthToken = token => {

    const cookies = new Cookies();
    let brand = cookies.get('BRAND');
    let langSite = localStorage.getItem('lang_site');
    let uid = localStorage.getItem('uid');

    axios.defaults.headers.common['brand-code'] = brand || 'matthewsliquor';
    axios.defaults.headers.common['lang'] = langSite || 'en';

    if(uid){
        axios.defaults.headers.common['uid'] = uid;
    }
    // axios.defaults.headers.common['brand-code'] = 'qc';

    if (token) {
        // Apply to every request
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    else {
        // Delete auth header
        delete axios.defaults.headers.common['Authorization'];
    }
};

export default setAuthToken;
