import React, {useState, useEffect, useContext} from "react";
import './Room.sass';
import './ButtonDirect.sass';
import 'bootstrap/dist/css/bootstrap.min.css';
import Config from '../../../config';
import {useGlobal} from "reactn";
import Customer from "../components/Customer";
import {FiEdit, FiSearch, FiChevronDown, FiChevronUp} from "react-icons/fi";
import {Button, Form, Modal, Image} from "react-bootstrap";
import updateCustomer from "../../../actions/updateCustomer";
import SearchBar from "../components/SearchBar";
import Notes from "../components/Notes";
import SearchPage from "./SearchPage";
import SocialProfile from "./SocialProfile";
import TagModal from "./TagModal";
import Loading from "./Loading";
import {useDispatch, useSelector} from "react-redux";
import store from "../../../store";
import updateChatNotification from "../../../actions/updateChatNotification";
import detectCustomer from "../../../actions/detectCustomer";
import removeLinkCustomer from "../../../actions/removeLinkCustomer";
import updateMappingCustomer from "../../../actions/updateMappingCustomer";
import SweetAlert from "react-bootstrap-sweetalert";
import icons from "../../../assets"
import Actions from "../../../constants/Actions";
import getRooms from "../../../actions/getRooms";
import sendMessageApi from "../../../actions/message";
import {useHistory, useLocation} from "react-router-dom";
import { AppContext } from '../../../context/AppContext';
import getListTagByUserApi from "../../../actions/getListTagByUser";

const Room = () => {
    const room = useSelector(state => state.io.room);
    const user = useGlobal('user')[0];
    const brand = useGlobal('brand')[0];
    const location = useLocation();
    const history = useHistory();

    const { i18n } = useContext(AppContext);

    //
    const socialActive = useGlobal('socialActive')[0];
    const activeChannel = useGlobal('activeChannel')[0];
    const activeMessageStatus = useGlobal('activeMessageStatus')[0];
    const activeTags = useGlobal('activeTags')[0];
    const searchText = useGlobal('search')[0];
    //
    const [showModalUpdateTitle, setShowModalUpdateTitle] = useGlobal('showModalUpdateTitle');
    const [showModalAddTag, setShowModalAddTag] = useGlobal('showModalAddTag');
    const setShowPipeline = useGlobal('showPipeline')[1];
    
    const setSearchPage = useGlobal('searchPage')[1];
    const [customerInfo, setCustomerInfo] = useState(null);
    
    const [searchResultCustomer, setSearchResultCustomer] = useState([]);
    const [isDetectCustomer, setIsDetectCustomer] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [showAlert, setShowAlert] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingCustomerInfo, setLoadingCustomerInfo] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [showSocialProfile, setShowSocialProfile] = useState(false);
    const [showListAction, setShowListAction] = useState(true);

    const setUserActiveTagGlobal = useGlobal('userActiveTag')[1];
    const setUserSelectedTagGlobal = useGlobal('userSelectedTag')[1];

    // const routeLists = useGlobal('routeLists')[0];
    
    let userChat = {
        firstName: 'A', lastName: 'A'
    };

    userChat = room.owner;

    useEffect(() => {

        // nhan post message tu parent
        window.addEventListener('message', function (event) {
            // Check sender origin to be trusted
            // if (event.origin !== domain) return;

            var data = event.data;
            if(data.func === 'addSuccessCustomerLead'){
                addSuccessCustomerLead(data.message);
            } else if(data.func === 'cancelCustomerLead'){
                cancelCustomerLead(data.message);
            } else if(data.func === 'cancelDeal'){
                cancelDeal(data.message);
            } else if(data.func === 'addSuccessCustomerDeal'){
                addSuccessCustomerDeal(data.message);
            } else if(data.func === 'cancelCustomer'){
                cancelCustomer(data.message);
            } else if(data.func === 'editSuccessCustomerLead'){
                editSuccessCustomerLead(data.message);
            } else if(data.func === 'editSuccessCustomer'){
                editSuccessCustomer(data.message);
            } else if(data.func === 'addSuccessCustomer'){
                addSuccessCustomer(data.message);
            } else if(data.func === 'cancelOrder'){
                cancelOrder(data.message);
            } else if(data.func === 'addSuccessOrder'){
                addSuccessOrder(data.message);
            } else if(data.func === 'cancelAppointment'){
                cancelAppointment(data.message);
            } else if(data.func === 'addSuccessAppointmentSchedule'){
                addSuccessAppointmentSchedule(data.message);
            } else if(data.func === 'cancelWork'){
                cancelWork(data.message);
            } else if(data.func === 'addSuccessManagerWork'){
                addSuccessManagerWork(data.message);
            }

            setLoading(false);
        });

    }, []);

    useEffect(() => {
        //Nhận diện KH
        detectCustomerAction(userChat._id);
        setSearchResultCustomer([]);
        sessionStorage.setItem('room_id', room._id);

    }, [room, userChat]);

    const reloadRooms = () => {
        getRooms(searchText, socialActive, activeChannel, activeMessageStatus, activeTags)
            .then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms}))
            .catch(err => console.log(err));
    }

    const detectCustomerAction = (user_id) => {
        // handleSetLoading(true);
        setLoadingCustomerInfo(true);
        detectCustomer({user_id}).then(res => {
            setLoading(false);
            if(res.data.error === 0 && res.data.data){
                let data = res.data.data;

                if(data.customer_id || data.customer_lead_id){
                    let customer = data;
                    userChat.mapping_id = data.mapping_id;
                    userChat.customer_id = customer.customer_id;
                    userChat.customer_lead_id = customer.customer_lead_id;
                    userChat.phone = customer.phone;
                    userChat.point = customer.point ?? 0;
                    userChat.level = customer.level ?? '';
                    userChat.code = customer.customer_code;
                    userChat.lead_code = customer.customer_lead_code;
                    userChat.email = customer.email;
                    userChat.address = customer.full_address || customer.address;
                    userChat.type = customer.type;
                    userChat.related_user = customer.related_user;
                    userChat.journey_tracking = customer.journey_tracking;
                    userChat.journey_edit = customer.journey_edit;
                    userChat.journey_code = customer.journey_code;
                    userChat.pipeline_name = customer.pipeline_name;
                    setCustomerInfo(userChat);
                    setIsDetectCustomer(true);
                    setCustomerName(customer.full_name);
                    setShowPipeline(true);
                }
                else{
                    userChat.mapping_id = data.mapping_id;
                    setCustomerInfo(userChat);
                    setCustomerName(userChat.firstName);
                    setIsDetectCustomer(false);
                    setShowPipeline(false);
                }

                store.dispatch({type: Actions.SET_CUSTOMER, customer: userChat})
            }
            else{
                setShowPipeline(false);
                setCustomerName(userChat.firstName);
                setIsDetectCustomer(false);
                store.dispatch({type: Actions.SET_CUSTOMER, customer: {}})
            }

            setLoadingCustomerInfo(false);
        }).catch(err => {
            setLoadingCustomerInfo(false);
            console.log(err);
        });
    }

    const resetCustomer = () => {
        userChat.customer_id = null;
        userChat.customer_lead_id = null;
        userChat.phone = null;
        userChat.code = null;
        userChat.lead_code = null;
        userChat.email = null;
        userChat.address = null;
        userChat.type = null;
    }

    const Picture = ({room, userChat}) => {
        if (room && room.shieldedID)
            return <img src={`${Config.url || ''}/api/images/${room.shieldedID}/256/${brand}`} alt="avatar" />;
        else
            return <div className="img">{`${userChat.firstName.substr(0,1)}${userChat.lastName.substr(0,1)}`}</div>;
    };

    const rows = [];
    let rowIndex = 0;
    let row = [];

    room.images.forEach(message => {
        row.push(message);
        if (row.length === 2) {
            rows.push(row);
            rowIndex++;
            row = [];
        }
    });
    if (row.length > 0) rows.push(row);

    // Đổi tên KH
    const handleSubmitUpdateTitle = async e => {
        e.preventDefault();
        const {title} = e.target.elements;
        if(title.value){
            const res = await updateCustomer({id: customerInfo.customer_id || customerInfo.customer_lead_id, customer_type: customerInfo.type, data: {full_name: title.value}});

            if(res.data.error === 0 && res.data.data){
                setCustomerName(res.data.data.full_name);
            }

            setShowModalUpdateTitle(false);
        }
    }

    const handleSetLoading = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const getAllTag = () => {
        getListTagByUserApi({user_id: customerInfo._id}).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setUserSelectedTagGlobal(result);
                const listTagShow = result && result.filter(e => e.isActive === true);
                setUserActiveTagGlobal(listTagShow);
            }
        }).catch(err => console.log('getListTagApi', err));
    }

    // Xóa liên kết KH
    const handleRemoveLinkCustomer = () => {
        handleSetLoading(true);
        removeLinkCustomer({
            user_id: userChat._id,
            customer_id: customerInfo.customer_id, 
            customer_lead_id: customerInfo.customer_lead_id
        }).then(res => {
            setLoading(false);
            if(res.data.error === 0){
                detectCustomerAction(userChat._id);
                resetCustomer();
                reloadRooms();
                getAllTag();
                showAlertAction('success', res.data.message);
            }
            else{
                showAlertAction('warning', res.data.message);
            }
        }).catch(err => showAlertAction('warning', err));


        //post qua epoints clear data
        window.parent.postMessage({
            'func': 'unlinkChCustomer',
            'message': {
                ch_customer_id : customerInfo.mapping_id,
                full_name : customerName
            }
        }, "*");
    }

    const actionEditCustomer = () => {
        if(customerInfo && customerInfo.customer_id){
            showEditCustomer();
        }
        else{
            showEditCustomerLead();
        }
    }

    const showEditCustomer = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showEditCustomer',
            'message': {
                customer_id : customerInfo.customer_id
            }
        }, "*");

        var count = 0;

        //Cheat reload customer info when edit
        var intervalID = setInterval(function () {
            let chatUser = JSON.parse(sessionStorage.getItem('user_chat'));
            detectCustomerAction(chatUser._id);

            if (++count === 1) {
                window.clearInterval(intervalID);
            }
        }, 7000);
    }

    const showEditCustomerLead = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showEditCustomerLead',
            'message': {
                customer_lead_id : customerInfo.customer_lead_id
            }
        }, "*");
    }

    const editSuccessCustomerLead = (data) => {
        console.log('editSuccessCustomerLead', data);
        let chatUser = JSON.parse(sessionStorage.getItem('user_chat'));
        if(data){
            detectCustomerAction(chatUser._id);
        }
    }

    const editSuccessCustomer = (data) => {
        console.log('editSuccessCustomer', data);
        let chatUser = JSON.parse(sessionStorage.getItem('user_chat'));
        if(data){
            detectCustomerAction(chatUser._id);
        }
    }

    //Add CPO Customer
    const showAddCustomerLead = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showAddCustomerLead',
            'message': {
                full_name : customerInfo?.firstName || "",
                ch_customer_id : customerInfo.mapping_id,
            }
        }, "*");
    }

    //Add order success
    const cancelCustomerLead = (data) => {
        setLoading(false);
        console.log('cancelCustomerLead')
    }

    //Add CPO customer success
    const addSuccessCustomerLead = (data) => {
        console.log('addSuccessCustomerLead', data);
        if(data && data.ch_customer_id){
            updateMappingCustomer(data).then(res => {
                console.log('res.update.mapping', res.data);
                if(res.data.error === 0 && res.data.data){
                    let result = res.data.data;
                    detectCustomerAction(result);
                }
            }).catch(err => console.log(err));
        }
    }

    const cancelDeal = () => {
        setLoading(false);
    }

    //Tạo cơ hội bán hàng
    const showAddCustomerDeal = () => {
        handleSetLoading(true);

        let data = {
            ch_customer_id : customerInfo.mapping_id,
            full_name : customerName
        };

        if(customerInfo.customer_id){
            data.object_id = customerInfo.customer_id;
            data.object_type = 'customer';
        }

        if(customerInfo.customer_lead_id){
            data.object_id = customerInfo.customer_lead_id;
            data.object_type = 'lead';
        }
        
        window.parent.postMessage({
            'func': 'showAddCustomerDeal',
            'message': data
        }, "*");
    }

    // Tạo cơ hội bán hàng thành công
    const addSuccessCustomerDeal = (data) => {
        // var data = {"dead_id":1, dead_code:"DEALS_2401202201","ch_customer_id":"1"}
        console.log('addSuccessCustomerDeal', data);
    }

    // Add customer
    const showAddCustomer = () => {
        // handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showAddCustomer',
            'message': {
                ch_customer_id : customerInfo.mapping_id,
                // avatar : `${Config.url || ''}/api/images/${userChat.picture.shieldedID}/256/${brand}`,
                full_name : customerName
            }
        }, "*");
    }

    //Add order success
    const cancelCustomer = (data) => {
        setLoading(false);
    }

    // Add customer success
    const addSuccessCustomer = (data) => {
        console.log('addSuccessCustomer', data);
        if(data && data.ch_customer_id){
            updateMappingCustomer(data).then(res => {
                console.log('res.update.mapping', res.data);
                if(res.data.error === 0 && res.data.data){
                    let result = res.data.data;
                    detectCustomerAction(result);
                }
            }).catch(err => console.log(err));
        }
    }

    // Add tag
    const addTag = () => {
        setShowModalAddTag(true);
    }

    // Add order
    const showAddOrder = () => {
        // handleSetLoading(true);
        let message = {
            ch_customer_id : customerInfo.mapping_id,
            full_name : customerName
        };

        if(customerInfo.customer_id){
            message.customer_id = customerInfo.customer_id;
        }

        window.parent.postMessage({
            'func': 'showAddOrder',
            'message': message
        }, "*");
    }

    //Add order success
    const cancelOrder = (data) => {
        setLoading(false);
    }

    //Add order success
    const addSuccessOrder = (data) => {
        // var data = {"order_id":1, 'order_code' : "123123","ch_customer_id":"1"}
    }

    //Tạo nhắc nhở
    const showPopupRemind = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showPopupRemind',
            'message': {
                phone : customerInfo?.phone || null,
                ch_customer_id : customerInfo.mapping_id,
            }
        }, "*");
    }


    // Tạo lịch hẹn
    const showAppointmentSchedule = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showAppointmentSchedule',
            'message': {
                phone : customerInfo?.phone || null,
                ch_customer_id : customerInfo.mapping_id,
                customer_id : customerInfo?.customer_id,
            }
        }, "*");
    }

    //Add order success
    const cancelAppointment = (data) => {
        setLoading(false);
    }

    // Tạo lịch hẹn thành công
    const addSuccessAppointmentSchedule = async (data) => {
        // var data = {"customer_appointment_id":1, 'customer_appointment_code' : "123123","ch_customer_id":"1"}
        // alert('addSuccessAppointmentSchedule')
        if(data && data.customer_appointment_id){
            console.log('addSuccessAppointmentSchedule', data);
            sendMessageAppointment(data);
        }
    }

    const sendMessageAppointment = async (data) => {
        let roomID = sessionStorage.getItem('room_id')
        if(roomID && user){
            const noted = data.note ? `\n⭕ ${data.note}` : '';
            const message = `${i18n.t('confirm_appointment')}:\n👤 ${data.customer_name}\n📞 ${data.customer_phone}\n☑️${data.service_name}\n🗓 ${data.datetime_appointment}\n⏰ ${data.hour_appointment}${noted}`;
            let response = await sendMessageApi({roomID, authorID: user.id, content: message, contentType: 'text', action : 'message'});
            store.dispatch({type: Actions.MESSAGE, message: response.data.message});
            reloadRooms();
        }
        else{
            console.log('sendMessageAppointment error');
        }
    }

    // View customer detail
    const viewDetailCustomer = () => {
        // handleSetLoading(true);
        console.log('customerInfo', customerInfo);
        window.parent.postMessage({
            'func': 'showDetailCustomer',
            'message': {
                customer_id : customerInfo.customer_id || null,
                customer_lead_id: customerInfo.customer_lead_id || null,
                ch_customer_id: customerInfo.mapping_id
            }
        }, "*");
    }

    //Add order success
    const cancelWork = (data) => {
        setLoading(false);
    }

    // Tạo quản lý công việc
    const showAddManagerWork = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showAddManagerWork',
            'message': {
                customer_id: customerInfo?.customer_id || null,
                customer_lead_id: customerInfo?.customer_lead_id || null,
                ch_customer_id: customerInfo.mapping_id
            }
        }, "*");
    }

    // Tạo quản lý công việc thành công
    const addSuccessManagerWork = (data) => {
        // var data = {"manage_work_id":1, 'manage_work_code' : "123123","ch_customer_id":"1"}
        if(data.manage_work_id){
            console.log('addSuccessManagerWork', data);
        }
    }

    // Hiển thị thông báo
    const showAlertAction = (type = 'success',message, title = '') => {
        setShowAlert(<SweetAlert 
            type={type}
            title={title} 
            btnSize="sm" 
            confirmBtnText={i18n.t('confirm')} 
            // confirmBtnBsStyle="warning" 
            onConfirm={() => hideAlertAction()} 
            onCancel={() => hideAlertAction()}>
                {message}
            </SweetAlert>
        )
    }

    // Hiển thị confirm
    const showConfirmAction = (message, title = '') => {
        // handleSetLoading(true);
        setShowAlert(<SweetAlert 
            warning
            showCancel
            confirmBtnText={i18n.t('confirm')} 
            cancelBtnText={i18n.t('cancel')} 
            confirmBtnBsStyle="danger"
            title={title}
            btnSize="sm" 
            onConfirm={() => handleRemoveLinkCustomer()} 
            onCancel={() => hideAlertAction()}>
                {message}
            </SweetAlert>
        )
    }

    const hideAlertAction = () => {
        setLoading(false);
        setShowAlert(null);
    }

    const toggleListAction = () => {
        setShowListAction(e => !e);
    }

    const toggleSocialProfile = () => {
        setShowSocialProfile(e => !e);
    }

    const AvatarLinkedSocial = ({picture, user}) => {
        if (picture)
            return <img className="avatar" src={`${Config.url || ''}/api/images/${picture.shieldedID}/256/${brand}`} alt="avatar" />;
        else
            return <div className="img">{`${user.firstName.substr(0,1)}${user.lastName.substr(0,1)}`}</div>;
    };

    const handleIconChannel = (source) => {
        if(source === 'facebook'){
            return icons.Facebook;
        }
        else if(source === 'zalo'){
            return icons.Zalo;
        }
        else{
            return icons.ClientChat;
        }
    }

    const handleRouteRoom = async (room) => {
        if(room && room._id){
            let elmRoom = document.getElementsByClassName('room');
            for await (let item of elmRoom) {
                item.classList.remove('room-active')
            }

            const target = `/room/${room._id}`;
            if (location.pathname !== target){
                window.location.href = target;
                // await history.replace(target);
            }
            await updateChatNotification(room._id).then().catch(err => console.log(err));
        }
    }

    const createNotes = () => {
        setShowNote(true);
    }

    const handlePreventEvents = () => {
        let className = '';

        if(loading){
            return 'prevent-events';
        }

        if(userChat && userChat.isBlocked){
            return 'prevent-events';
        }

        return className;
    }

    // Kiểm tra xem là KH hay KH tiềm năng
    const detectIsCpoCustomer = (customerInfo && customerInfo.type === 'cpo') ? true : false;

    return (
        <>
            {showAlert}
            <div className="details-room">
                <SearchPage 
                    showAlertAction={showAlertAction} 
                    reloadRooms={reloadRooms}
                    detectCustomerAction={detectCustomerAction}
                    customerInfo={customerInfo}
                    searchResultCustomer={searchResultCustomer}
                    setSearchResultCustomer={setSearchResultCustomer}
                />
                {showModalAddTag && 
                <TagModal 
                    userChat={userChat} 
                    showModalAddTag={showModalAddTag} 
                    setShowModalAddTag={setShowModalAddTag} 
                    showAlertAction={showAlertAction}
                />}
                <Modal
                    show={showModalUpdateTitle}
                    onHide={() => setShowModalUpdateTitle(false)} animation={false}
                    // size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Form noValidate onSubmit={handleSubmitUpdateTitle}>
                        <Modal.Header closeButton>
                            <div className="modal-title h6" id="contained-modal-title-vcenter">{i18n.t('edit_customer_name')}</div>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                {/* <Form.Label>Tên khách hàng</Form.Label> */}
                                <Form.Control required
                                    className=""
                                    type="text"
                                    maxLength={20}
                                    placeholder="Nhập tên khách hàng"
                                    defaultValue={customerName}
                                    name="title" />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModalUpdateTitle(false)}>{i18n.t('cancel')}</Button>
                            <Button type="submit">{i18n.t('update')}</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                <div className="search-header">
                    <button className="btn btn-search" onClick={() => setSearchPage('product')}><FiSearch/> {i18n.t('search_product')}</button>
                    <button className="btn btn-search" onClick={() => setSearchPage('order')}><FiSearch/> {i18n.t('search_order')}</button>
                </div>
                {isDetectCustomer &&
                    (<button className={`btn customer-delink action-event ${handlePreventEvents()}`}
                            onClick={() => showConfirmAction(i18n.t('confirm_unlink'))}>
                        <Image width="16" src={icons.DeLink}/> {i18n.t('unlink')}
                    </button>
                )}
                <div className="profile">
                    <Picture room={room} userChat={userChat} />
                </div>

                {loadingCustomerInfo && <Loading/>}
                {!loadingCustomerInfo && (
                    <>
                        <div className="name">
                            {customerName && <span className="mr-10">{customerName.substr(0, 20)}{customerName.length > 20 && '...'}</span>}
                            <span className={`action-update-title action-event ${handlePreventEvents()}`} onClick={() => actionEditCustomer()}>{isDetectCustomer && <FiEdit/>}</span>
                        </div>

                        {isDetectCustomer && (
                            <>
                            <div className="customer-info">
                                {(customerInfo.code || customerInfo.lead_code) && (
                                    <div className="title"><Image width="16" src={icons.Code}/> <span>{customerInfo.code || customerInfo.lead_code}</span></div>
                                )}
                                {customerInfo.phone && (<div className="title"><Image width="16" src={icons.Phone}/> <span>{customerInfo.phone}</span></div>)}
                                {customerInfo.email && (<div className="title"><Image width="16" src={icons.Email}/> <span>{customerInfo.email}</span></div>)}
                                {customerInfo.address && (<div className="title"><Image width="16" src={icons.Address}/> <span>{customerInfo.address}</span></div>)}

                                {customerInfo.related_user && customerInfo.related_user.length > 0 && (
                                    <div className="title social-linked">
                                        <div><Image width="18" src={icons.SocialLink}/> <span>{i18n.t('linked_account')}</span></div>
                                        {customerInfo.related_user.map(item => (
                                            <div key={item._id} className="item" title={item.firstName} onClick={() => handleRouteRoom(item.room)}>
                                                <AvatarLinkedSocial picture={item.picture} user={item} />
                                                <Image className="social-icon" width="20" src={handleIconChannel(item.source)}/>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <div className={`btn-detail-customer text-center mb-2 action-event ${handlePreventEvents()}`} onClick={() => viewDetailCustomer()}>{i18n.t('more_detail')}</div>
                                
                            </div>
                            </>
                        )}
        
                        {!isDetectCustomer && (
                            <>
                                <div className="title">{i18n.t('not_info_customer')}</div>
                                <div className="text-bold mb-2 w-100 ml-15 text-left">{i18n.t('enter_info_to_search_customer')}</div>
                                <SearchBar setSearchResultCustomer={setSearchResultCustomer} setSearchPage={setSearchPage} showAlertAction={showAlertAction}/>
                            </>
                        )}   
                    </>
                )}

                <div className="action-group">
                    {/* <div className="action-title" onClick={() => toggleSocialProfile()}>
                        <span>{i18n.t('social_profile')}</span>
                        <span>{showSocialProfile ? <FiChevronUp/> : <FiChevronDown/>}</span>
                    </div> */}

                    <div className={`detail-action-group`}>
                        {showSocialProfile && <SocialProfile/>}
                    </div>
                </div>

                <div className="action-group">
                    <div className="action-title" onClick={() => toggleListAction()}>
                        <span>{i18n.t('features')}</span>
                        <span>{showListAction ? <FiChevronUp/> : <FiChevronDown/>}</span>
                    </div>

                    <div className={`detail-action-group`}>
                        {loadingCustomerInfo && <Loading/>}
                        {!loadingCustomerInfo && showListAction && (
                            <>
                            <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => addTag()}><Image width="20" src={icons.AddTag}/><span>{i18n.t('tags')}</span></div>
                            {!isDetectCustomer ? (
                                <>
                                    {/* {(routeLists && routeLists.includes('admin.order.add')) && ()} */}
                                    <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddOrder()}><Image width="20" src={icons.AddOrder}/><span>{i18n.t('create_order')}</span></div>
                                    {/* {(routeLists && routeLists.includes('admin.customer.add')) && ()} */}
                                    <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddCustomer()}><Image width="20" src={icons.AddCustomer}/><span>{i18n.t('create_customer')}</span></div>
                                    {/* {(routeLists && routeLists.includes('customer-lead.create')) && ()} */}
                                    <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddCustomerLead()}><Image width="20" src={icons.AddCustomerPotential}/><span>{i18n.t('create_customer_lead')}</span></div>
                                </>
                            ):(
                                <>
                                    {detectIsCpoCustomer ? (
                                        // (routeLists && routeLists.includes('admin.customer.add')) && ()
                                        <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddCustomer()}><Image width="20" src={icons.AddCustomer}/><span>{i18n.t('create_customer')}</span></div>
                                    ) : (
                                        <>
                                        {/* {(routeLists && routeLists.includes('admin.order.add')) && ()} */}
                                        <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddOrder()}><Image width="20" src={icons.AddOrder}/><span>{i18n.t('create_order')}</span></div>
                                        {/* {(routeLists && routeLists.includes('admin.customer_appointment.submitModalAdd')) && ()} */}
                                        {/* <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAppointmentSchedule()}><Image width="20" src={icons.Calendar}/><span>{i18n.t('create_appointment')}</span></div> */}
                                        </>
                                    )}
                                    {/* {(routeLists && routeLists.includes('customer-lead.customer-deal.create')) && ()} */}
                                    <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddCustomerDeal()}><Image width="20" src={icons.Sales}/><span>{i18n.t('create_deal')}</span></div>
                                </>
                            )}
            
                            {/* {(routeLists && routeLists.includes('manager-work.detail-remind')) && ()}                 */}
                            <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showPopupRemind()}><Image width="20" src={icons.AddRemind}/><span>{i18n.t('create_reminder')}</span></div>
                            
                            {/* {(routeLists && routeLists.includes('manager-work.detail.show-popup-work-child')) && ()} */}
                            <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddManagerWork()}><Image width="20" src={icons.AddTask}/><span>{i18n.t('create_task')}</span></div>
                            
                            <div className={`detail-action-item ${loading ? 'prevent-events' : ''}`} onClick={() => createNotes()}><Image width="20" src={icons.AddNotes}/><span>{i18n.t('create_note')}</span></div>
                            <Notes
                                showNote={showNote} 
                                setShowNote={setShowNote} 
                                setShowAlert={setShowAlert}
                                showAlertAction={showAlertAction} 
                                hideAlertAction={hideAlertAction}/>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Room;
