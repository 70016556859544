import axios from "axios";
import Config from "../config";

const authenticationClient = (data, brand) => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/authentication-client",
        data: {token: data.token, channel_id: data.channelParam},
        headers: {
            'brand-code': brand || 'matthewsliquor'
        }
    });
};

export default authenticationClient;
