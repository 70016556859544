import React, {useRef, useEffect, useState, useContext} from 'react';
import "./Room.sass";
import {useGlobal} from "reactn";
import {FiSlash} from 'react-icons/fi';
import Picture from "../../../components/Picture";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../../constants/Actions";
import MasterConstant from "../../../constants/MasterConstant";
import updateChatNotification from "../../../actions/updateChatNotification";
import getNotificationUser from "../../../actions/getNotificationUser";
import {Image} from "react-bootstrap";
import icons from "../../../assets"
import { AppContext } from "../../../context/AppContext";

const Room = ({room}) => {
    // const onlineUsers = useSelector(state => state.io.onlineUsers);
    const currentRoom = useSelector(state => state.io.room);
    const [roomActive, setRoomActive] = useState(false);
    const user = useGlobal('user')[0];
    const setSearchPage = useGlobal('searchPage')[1];
    const setKeywordCustomer = useGlobal('keywordCustomer')[1];
    const { i18n } = useContext(AppContext);

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const elementRef = useRef(null);

    useEffect(() => {
        // setNewMess(room.messageUnSeen);

        if(currentRoom && room){
            if(currentRoom._id === room._id){
                setRoomActive(true);
            }
        }
    }, [room, currentRoom, roomActive])

    let userChat = room.owner;

    if (!userChat.firstName) {
        userChat = { ...userChat, firstName: 'Deleted', lastName: 'User' };
    }

    const getRoomName = () => {
        let fullName = `${userChat.firstName} ${userChat.lastName}`;
        return fullName ? fullName.trim().substr(0, 50) : '';
    }   

    
    let lastMessage = room.lastMessage;
    let text = '';

    if (!lastMessage && room.isGroup) text = 'New group created.';
    if (!lastMessage && !room.isGroup) text = `Không có tin nhắn với ${userChat.firstName}.`;

    if (!lastMessage) lastMessage = {};

    if (lastMessage.author === user.id && !room.isGroup) text += `${i18n.t('you')}: `;

    switch (lastMessage.type) {
        case 'file':
            text += i18n.t('file_sent');
            break;
        case 'image':
            text += i18n.t('image_sent');
            break;
        case 'sticker':
            text += i18n.t('sticker_sent');
            break;
        default:
            let finalContent = lastMessage.content;
            if(typeof finalContent != 'undefined'){
                let tagFullName, tagUserId;
                let tagUser = finalContent.match(/@((?!@).)*-((?!@).)*@/gm);
                if(tagUser){
                    tagUser.map(item => {

                        let tag = item.replace(/@/g, '');
                        let objTag = tag.split('-');
                        tagFullName = objTag[0];
                        tagUserId = objTag[1];
                        finalContent = finalContent.replace(item, '@' + tagFullName + ' ');
                    });
                }
            }


            text += (finalContent || '');
    }

    // const getStatus = () => {
    //     if (room.isGroup) return null;
    //     if (onlineUsers.filter(u => u.id === userChat._id && u.status === 'busy').length > 0) return 'busy';
    //     if (onlineUsers.filter(u => u.id === userChat._id && u.status === 'online').length > 0) return 'online';
    //     if (onlineUsers.filter(u => u.id === userChat._id && u.status === 'away').length > 0) return 'away';
    //     return null;
    // };

    const handleOnclickRoom = async () => {

        let elmRoom = document.getElementsByClassName('room');
        for await (let item of elmRoom) {
            item.classList.remove('room-active');
        }

        // Reset search customer
        setSearchPage('');
        setKeywordCustomer('');

        //Add class active
        elementRef.current.classList.add('room-active');
        // setNewMess(0);

        const target = `/room/${room._id}`;
        if (location.pathname !== target) await history.replace(target);
        if(room && room._id && room.messageUnSeen > 0){
            await updateChatNotification(room._id).then().catch(err => console.log(err));

            await getNotificationUser().then(res => {
                window.parent.postMessage({
                    func: 'pushNotification',
                    message: res?.data?.total || 0
                }, "*");

                dispatch({type: Actions.SET_USER_NOTIFICATION, notificationsUser: res.data});
            }).catch(err => console.log(err));
        }

        room.messageUnSeen = 0;
        elementRef.current.classList.remove('highlight');
    }

    const handleIconChannel = (room) => {
        let source = room.channel ? room.channel.source : null;
        if(source === 'facebook'){
            return icons.Facebook;
        }
        else if(source === 'zalo'){
            return icons.Zalo;
        }
        else{
            return icons.ClientChat;
        }
    }

    const handleIconCustomer = () => {
        let icon = '';

        if(!userChat){
            return icon;
        }

        if(userChat.customerId){
            icon = icons.Customer;
        }
        else if(userChat.cpoCustomerId){
            icon = icons.CpoCustomer;
        }

        if(icon !== ''){
            return (<span className="icon-user-room">
                <Image width="16" src={icon}/>
            </span>)
        }

        return icon;
    }

    const handleClassRoom = () => {
        let nameClass = '';

        if(roomActive){
            nameClass += 'room-active';
        }

        if(room.messageUnSeen > 0){
            nameClass += ' highlight';
        }

        return nameClass;
    }

    return (
        <div
            className={`room uk-flex ${handleClassRoom()}`}
            ref={elementRef}
            onClick={() => handleOnclickRoom()}>
            <div className="uk-flex uk-flex-middle">
                <div className="profile">
                    <Picture user={userChat} room={room}/>
                    <Image className="social-icon" width="22" src={handleIconChannel(room)}/>
                </div>
                {/* {getStatus() && <div className={`dot ${getStatus()}`} />} */}
            </div>
            <div className="text">
                <div className={`title d-flex`}>
                    {handleIconCustomer()}
                    <div>
                        <span>{getRoomName()}</span>
                        <span className={room.messageUnSeen > 0 ? 'room_noti' : ''}></span>
                    </div>
                </div>
                <div className="message">{text.substr(0, 60)}{text.length > 60 && '...'}</div>
                {room.channel && <div className="channel-name">{room.channel.nameApp}</div>}
            </div>
            <div className="controls">
                <div className="date">
                    <span className="room_date">{MasterConstant.timeSince(room.lastUpdate, i18n)}</span>
                    <span className="icon-block">{userChat?.isBlocked && <FiSlash/>}</span>
                </div>
            </div>
        </div>
    );
}

export default Room;
