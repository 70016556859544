import axios from "axios";
import Config from "../config";

const messageClient = data => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/v2/message-client",
        data: data
    });
};

export default messageClient;
