import { setGlobal } from 'reactn';
import setAuthToken from "./actions/setAuthToken";
import checkUserToken from "./actions/checkUserToken";
import authenticationClient from "./actions/authenticationClient";
import initIO from "./actions/initIO";
import store from "./store";
import jwtDecode from 'jwt-decode';
import Config from "./config";
import { Cookies } from "react-cookie";

const init = async () => {
    try {
        document.addEventListener('gesturestart', e => {
            e.preventDefault();
        });
    
        if (localStorage.getItem('app') !== 'Clover 2.x.x') {
            localStorage.clear();
            localStorage.setItem('app', 'Clover 2.x.x');
        }

        const cookies = new Cookies();

        //Lấy token từ query params (trường hợp những boxchat client)
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const tokenParam = urlParams.get('token'); 

        let eToken = '';
        let channelParam = '';
        if(tokenParam){
            eToken = tokenParam;
            channelParam = urlParams.get('channel'); 
            localStorage.setItem('chat_mode', "client");
            localStorage.removeItem("token");
        }
        else{
            eToken = cookies.get('EPOINTS_SSO');
            // eToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vc3RhZmYtYXBpL3VzZXIvcmVmcmVzaC10b2tlbiIsImlhdCI6MTcxOTQ1NDA0OSwiZXhwIjoxNzE5NTAxMzQ1LCJuYmYiOjE3MTk0Nzk3NDUsImp0aSI6InhxdDc4Z0NTSkNNOGhHdlQiLCJzdWIiOjE1LCJwcnYiOiJhMGYzZTc0YmVkZjUxMmM0Nzc4Mjk3ZGU1ZjkyMDg2ZGFkMzljYTlmIiwic2lkIjoidGFtIiwiYnJhbmRfY29kZSI6InNhbGUifQ.mY3CojoOfHkIePmZ4fMz3pa7oYJQzP-FR-t5G3A4mZE";
            Config.epointsToken = eToken;
            localStorage.setItem('chat_mode', "server");
        }
    
        const decoded = jwtDecode(eToken, {complete: true});
        let brand = decoded?.brand_code ?? decoded?.brand;
        let usernamePortal = decoded?.sub;

        if(!tokenParam){
            localStorage.setItem('uid', usernamePortal);
        }else{
            localStorage.removeItem("uid");
        }

        cookies.set('BRAND', brand);
    
        let token = localStorage.getItem('token');
    
        if(token && eToken){
            const eDecoded = jwtDecode(eToken, {complete: true});
            const decoded = jwtDecode(token, {complete: true});
    
            if(eDecoded.sid !== decoded.sid){
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                token = null;
            }
        }
    
        if(!token){
            token = eToken;
        }
        let userString = localStorage.getItem('user');
        let user = userString ? JSON.parse(userString) : null;
        if (token) {
            const dateNow = new Date();
            let isExpired = (decoded.exp * 1000 < dateNow.getTime());
            let result;
    
            if (!isExpired) {
                try {
                    let res = null;
                    if(tokenParam){
                        res = await authenticationClient({token, channelParam}, brand);
                    }
                    else{
                        res = await checkUserToken(token, brand);
                    }

                    result = res.data;
    
                } catch (e) {
                    console.log(e)
                    result = null;
                }
            }
    
            if (!result || result.error) {
                console.log({error: result.error});
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                token = localStorage.getItem('token');
                userString = localStorage.getItem('user');
                user = userString ? JSON.parse(userString) : null;
            } else {
                localStorage.setItem('token', result.token);
                localStorage.setItem('user', JSON.stringify(jwtDecode(result.token)));
                token = localStorage.getItem('token');
                userString = localStorage.getItem('user');
                user = userString ? JSON.parse(userString) : null;
            }
        }
    
        if (token) {
            setAuthToken(token);
            store.dispatch(initIO(token));
        }
    
        const state = {
            version: '2.5.2',
            entryPath: window.location.pathname,
            token,
            brand: brand ?? null,
            user: user || (token ? jwtDecode(token) : {}),
            channels: [],
            rooms: [],
            searchResults: [],
            searchResultsATG: [],
            favorites: [],
            meetings: [],
            notification: [],
            nav: 'rooms',
            search: '',
            over: null,
            isPicker: false,
            messages: [],
            streams: [],
            inCall: false,
            video: true,
            audio: true,
            audioStream: null,
            videoStream: null,
            screenStream: null,
            callStatus: null,
            counterpart: null,
            callDirection: null,
            meeting: null,
            showPanel: true,
            panel: 'standard',
            searchPage: '',
            newGroupUsers: [],
            newGroupUsersATG: [],
            openInfo: false,
            showReply: false,
            showModalUpdateTitle: false,
            showModalAddToGroup : false,
            showModalAddTag : false,
            showModalForward : false,
            roomActive : false,
            reply: false,
            messageReply: false,
            showConfirm: false,
            showAlert: false,
            currentMessage: '',
            messageGetData: [],
            messageModeAction: 'new',
            messageForward: [],
            userSelectedTag: [],
            userActiveTag: [],
            customerInfoGlobal: [],
            showPipeline: false,
            routeLists: [],
            parentDomain: '',
            keywordCustomer: '',
            chatMode: 'server'
        };
    
        setGlobal(state).then(() => console.log('Global state init complete!'));
    } catch (error) {
        console.log('Token Missing: ', error.message);
    }
}

export default init;
