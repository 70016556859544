import React, {useContext, useState} from 'react';
import "./SocialProfile.sass";
import {useSelector} from "react-redux";
import Picture from "../../../components/Picture";
import icons from "../../../assets"
import {Image} from "react-bootstrap";
import { AppContext } from "../../../context/AppContext";
import {FiCheckCircle, FiMinusCircle} from "react-icons/fi";
import moment from "moment";

const SocialProfile = () => {
    const room = useSelector(state => state.io.room);
    const userChat = room.owner;
    const { i18n } = useContext(AppContext);
    const [showModalZaloMessTrans, setShowModalZaloMessTrans] = useState(false);

    const handleIconChannel = (source) => {
        if(source === 'facebook'){
            return icons.Facebook;
        }
        else if(source === 'zalo'){
            return icons.Zalo;
        }
        else{
            return icons.ClientChat;
        }
    }

    const getTemplateAnchor = (href, title = '') => {
        if(!title){
            title = href;
        }

        return <a href={href} target="_blank" rel="noreferrer">{title}</a>
    }

    const getUserSocialPage = () => {
        if(userChat && userChat.phone && room.source === 'zalo') return getTemplateAnchor(`https://zalo.me/${userChat.phone}`);

        return userChat.userSocialId;
    }

    const getSocialPage = () => {
        if(room.source === 'facebook'){
            return getTemplateAnchor(`https://www.facebook.com/${room.channel.socialChanelId}`);
        }
        else{
            return getTemplateAnchor(`https://oa.zalo.me/${room.channel.socialChanelId}`);
        }
    }

    return (
        <div className="social-profile">
            <div className="profile-title">
                <div className="profile conversation">
                    <Picture user={userChat} room={room}/>
                    <Image className="social-icon" width="20" src={handleIconChannel(room.source)}/>
                </div>
                <div className="profile-name">{userChat.firstName}</div>
            </div>
            <div className="profile-info">
                <span><strong>{room.source === 'facebook' ? 'PSID' : 'UID'}:</strong></span>
                <span>{getUserSocialPage()}</span>
                <span><strong>{room.source === 'facebook' ? 'Fanpage' : 'OA'}:</strong></span>
                <span>{room.channel.nameApp}</span>
                <span><strong>{room.source === 'facebook' ? 'Link Fanpage' : 'Link OA'}:</strong></span>
                <span>{getSocialPage()}</span>
                {room.source === 'zalo' && 
                    <>
                        <span><strong>{i18n.t('status')}:</strong></span>
                        <span>
                            {room.owner.isFollowed 
                                ? <span><FiCheckCircle color={'#0DCB1F'}/> {i18n.t('follow')} </span>
                                : <span><FiMinusCircle color={'#F00808'}/> {i18n.t('unfollow')}</span>
                            }
                        </span>
                    </>
                }
                <span><strong>{i18n.t('approach_date')}:</strong></span>
                <span>{moment(room.createdAt).format('DD/MM/yyyy - HH:mm')}</span>
            </div>
        </div>
    );
}

export default SocialProfile;
