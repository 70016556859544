import axios from "axios";
import Config from "../config";

const checkUserToken = (token, brand) => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/check-user-token",
        data: {token},
        headers: {
            'brand-code': brand || 'matthewsliquor'
        }
    });
};

export default checkUserToken;
