import React, {useState, useRef, useEffect, useContext} from 'react';
import Message from "./Message";
import { Lightbox } from "react-modal-image";
import Config from "../../../config";
import {useGlobal} from "reactn";
import './Messages.sass';
import {FiEyeOff, FiEye, FiAlertCircle} from "react-icons/fi";
import {Image} from "react-bootstrap";
import PipelineSlide from "../../Details/components/PipelineSlide";
import {useDispatch, useSelector} from "react-redux";
import getMoreMessages from "../../../actions/getMoreMessages";
import getListTagByUserApi from "../../../actions/getListTagByUser";
import Actions from "../../../constants/Actions";
import store from "../../../store";
import { AppContext } from "../../../context/AppContext";

const Messages = ({canSend}) => {
    const user = useGlobal('user')[0];
    const brand = useGlobal('brand')[0];
    const messages = useSelector(state => state.io.messages) || [];
    const room = useSelector(state => state.io.room);
    const customer = useSelector(state => state.io.customer);
    const chatMode = useGlobal('chatMode')[0];

    const setUserSelectedTagGlobal = useGlobal('userSelectedTag')[1];
    const [userActiveTag, setUserActiveTagGlobal] = useGlobal('userActiveTag');
    const showPipeline = useGlobal('showPipeline')[0];
    const [loading, setLoading] = useState(false);
    const typing = useSelector(state => state.messages.typing);
    const [loadingTag, setLoadingTag] = useState(false);
    const [showListTag, setShowListTag] = useState(true);
    const [heightTagList, setHeighTagList] = useState(0);
    const { i18n } = useContext(AppContext);

    const chat = useRef(null);

    const [open, setOpen] = useState(null);

    useEffect(() => {
        if (chat.current){
            keepScrollAtBottom()
        };
    }, [messages]);

    useEffect(() => {
        setUserActiveTagGlobal([]);
        //Reset customer global
        store.dispatch({type: Actions.SET_CUSTOMER, customer: {}});
    }, []);

    useEffect(() => {
        if (typing && chat.current) keepScrollAtBottom();
    }, [typing])

    useEffect(() => {
        let isMounted = true;
        setLoadingTag(true);

        getListTagByUserApi({user_id: userChat._id}).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setUserSelectedTagGlobal(result);
                const listTagShow = result && result.filter(e => e.isActive === true);
                setUserActiveTagGlobal(listTagShow);

                if(isMounted){
                    setLoadingTag(false);

                    //set padding-top message
                    const tagListElement = document.getElementsByClassName('tags-wrapper');

                    if(tagListElement[0]){
                        let heightTag = tagListElement[0].clientHeight;
                        if(heightTag){
                            setHeighTagList(heightTag);
                        }
                    }
                }
                
            }
        }).catch(err => console.log('getListTagApi', err));

        return () => {
            isMounted = false;
        };
    }, [room]);

    let userChat = {
        firstName: 'A', lastName: 'A'
    };

    if (!room.isGroup && room.owner) {
        userChat = room.owner;
    }

    if ( typing !== false && room && room.isGroup && room.owner) {
        userChat = room.owner;
    }

    const Messages = messages.map((message, index) => {
        let messageData = [];

        if(room.messageSeen){
            room.messageSeen.forEach((messageSeen, index)=>{
                if(messageSeen.message === message._id){
                    messageData.push(messageSeen);
                }
            });
        }
        
        return <Message
            key={message._id}
            message={message}
            previous={messages[index - 1]}
            next={messages[index + 1]}
            onOpen={setOpen}
            messageSeen={messageData}
            room={room}
        />;
    });

    const keepScrollAtBottom = () => {
        chat.current.scrollTop = chat.current.scrollHeight;
    };

    const onScroll = () => {
        if (chat.current.scrollTop > 0) {
            return;
        }

        if (loading) return;
        setLoading(true);

        // Lưu vị trí cuộn hiện tại và offset
        const currentScrollTop = chat.current.scrollTop;
        const currentOffset = chat.current.scrollHeight - currentScrollTop;

        getMoreMessages({roomID: room._id, firstMessageDate: messages[0].date}).then(res => {
            let messages = res.data.messages;
            if(messages.length){
                store.dispatch({type: Actions.MORE_MESSAGES, messages: res.data.messages});
                setLoading(false);
                
                // Tính toán vị trí cuộn mới
                const newScrollTop = chat.current.scrollHeight - currentOffset;
                // Cập nhật vị trí cuộn
                chat.current.scrollTop = newScrollTop;
            }
            else{
                chat.current.scrollTop = 1;
            }

        }).catch(err => {
            setLoading(false);
        });
    };

    const handleShowListTag = () => {
        setShowListTag(e => !e);
    }

    return (
        <>
        {!canSend && (<div className="chat-alert">
            <FiAlertCircle size="20" color={'#dd2c00'}/>
            <span>
            {i18n.t('oa_warning_7_days')} <a href="https://oa.zalo.me/home/resources/news/thong-bao-chinh-sach-gui-tin-va-quy-dinh-phi-gui-tin_1433049880779375099" target="_blank" rel="noreferrer">
                    {i18n.t('more_detail')}
                </a>
            </span>
        </div>)}
        {(!loadingTag && userActiveTag && userActiveTag.length > 0 && chatMode !== 'client') && (
            <div className="tags-wrapper">
                {showListTag && (
                    <div className="lists">
                        {userActiveTag.map(tag => <div key={tag._id} className="user-label-tag" style={{ background: tag.color }}>{tag.name}</div>)}
                    </div>
                )}
                <div className="icon" onClick={() => handleShowListTag()}>{showListTag ? <FiEyeOff/> : <FiEye/>}</div>
            </div>
        )}
        <div className={`messages-wrapper messages-wrapper-fix ${showPipeline ? 'has-pipeline' : ''}`} 
            ref={chat} onScroll={onScroll} 
        >

            {showPipeline && <PipelineSlide/>}
            <div className="messages-container">
                {open && (
                    <Lightbox
                        medium={`${Config.url || ''}/api/images/${open.content}/1024/${brand}`}
                        // large={`${Config.url || ''}/api/images/${open.content}/2048/${brand}`}
                        alt="Lightbox"
                        hideDownload={true}
                        onClose={() => setOpen(null)}
                    />
                )}
                {Messages}
                {typing?.isTyping && <div className="message left attach-previous">
                    <div className="picture">
                        {/* <Picture user={userChat} /> */}
                    </div>
                    <div className="content-x">
                        <div className="bubble bubble-left left" style={{width: 50}}>
                            <div id="wave">
                                <span className="dot"/>
                                <span className="dot"/>
                                <span className="dot"/>
                            </div>
                        </div>
                        <div className={`message-details left`} style={{color: "transparent"}}>-</div>
                    </div>
                </div>}
            </div>
        </div>
        </>
    );
};

export default Messages;
