import React, {useContext} from 'react';
import "./Room.sass";
import './Order.sass';
import MasterConstant from "../../../constants/MasterConstant";
import { AppContext } from "../../../context/AppContext";

const Order = ({order}) => {
    const { i18n } = useContext(AppContext);

    const getColorStatusOrder = (status) => {
        switch (status) {
            case 'new':
                return '#00a650';
            case 'paysuccess':
                return '#5867dd';
            case 'confirmed':
                return '#ffb822';
            case 'pay-half':
                return '#36a3f7';
            case 'ordercancle':
                return '#ed1b24';
            default:
                return '#5867dd';
        }
    }

    const handlePriceRemain = (amount, amount_paid) => {
        if(!amount) amount = 0;
        if(!amount_paid) amount_paid = 0;

        return amount - amount_paid;
    }

    return (
        <div className="order-item">
            <div className="order-code">
                <span className="code">{order.order_code}</span>
                <span className="status" style={{ background: getColorStatusOrder(order.process_status) }}>{order.process_status_name}</span>
            </div>
            <p>{i18n.t('customer')}: {order.full_name}</p>
            <p>{i18n.t('total_price')}: {order.amount ? MasterConstant.formatPrice(order.amount) : 0} $</p>
            <p>{i18n.t('paid')}: {order.amount_paid ? MasterConstant.formatPrice(order.amount_paid || 0) : 0} $</p>
            <p className="d-flex justify-space-between">
                <span>{i18n.t('remain')}: {MasterConstant.formatPrice(handlePriceRemain(order.amount, order.amount_paid))} $</span>
                <div className={`btn-detail-order text-center`}>
                    <a href={`${MasterConstant.getParentOrigin()}/admin/order/detail/${order.order_id}`} target="_blank" rel="noreferrer">{i18n.t('more_detail')}</a>
                </div>
            </p>
        </div>
    );
}

export default Order;
