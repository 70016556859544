import axios from "axios";
import Config from "../config";

const getMoreMessages = ({roomID, firstMessageDate}) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/v2/message/more",
        data: { roomID, firstMessageDate }
    });
};

export default getMoreMessages;
